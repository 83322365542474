import { PROJECT_TOKEN_REDUX_CONST, RESET } from "../../constants";
import { snsDataType } from "../../models/sns.data";
import { twilioDataType } from "../../models/twilio.data";
import {
    ActivateInactivateServicesType,
    ProjectTokenInfoType,
    UpdateAws,
    UpdateAwsType,
    UpdateGooglePush,
    UpdateGooglePushType,
    UpdateSocial,
    UpdateSocialType,
    UpdateTwilioType,
    activateInactivateServices,
    UpdateSMTP,
    UpdateSMTPType,
    UpdateSNSType
} from "./types";


export const SaveProjectsTokenList = (payload: any): ProjectTokenInfoType => ({
    type: PROJECT_TOKEN_REDUX_CONST.GET_ALL_TOKEN_ALL,
    payload: payload
});

export const SaveProjectIdTokensDetail = (payload: any): ProjectTokenInfoType => ({
    type: PROJECT_TOKEN_REDUX_CONST.CURRENT_PROJECT,
    payload: payload
});

export const ActivateInactivateServices = (payload: activateInactivateServices): ActivateInactivateServicesType => ({
    type: PROJECT_TOKEN_REDUX_CONST.ACTIVATE_INACTIVATE_SERVICES,
    payload: payload
});

export const ResetProjectsTokenInfo = () => ({
    type: RESET
});

export const UpdateGooglePushToken = (payload: UpdateGooglePush): UpdateGooglePushType => ({
    type: PROJECT_TOKEN_REDUX_CONST.UPDATE_GOOGLE_PUSH_TOKEN,
    payload: payload
})
export const UpdateAwsToken = (payload: UpdateAws): UpdateAwsType => ({
    type: PROJECT_TOKEN_REDUX_CONST.UPDATE_AWS_TOKEN,
    payload: payload
})
export const UpdateSocialToken = (payload: UpdateSocial): UpdateSocialType => ({
    type: PROJECT_TOKEN_REDUX_CONST.UPDATE_SOCIAL_TOKEN,
    payload: payload
})
export const UpdateTwiloToken = (payload: twilioDataType): UpdateTwilioType => ({
    type: PROJECT_TOKEN_REDUX_CONST.UPDATE_TWILIO_TOKEN,
    payload: payload
})
export const UpdateSNSToken = (payload: snsDataType): UpdateSNSType => ({
    type: PROJECT_TOKEN_REDUX_CONST.UPDATE_SNS_TOKEN,
    payload: payload
})

export const UpdateSMTPToken = (payload: UpdateSMTP): UpdateSMTPType => ({
    type: PROJECT_TOKEN_REDUX_CONST.UPDATE_SMTP_TOKEN,
    payload: payload
})
