import { Dispatch } from 'redux';
import { NOTIFIER_ACTION_LIST, NOTIFIER_ACTION_STATUS, NOTIFIER_REDUX, NOTIFIER_STATUS } from '../../constants';
import { NotifierData, NotifierAction } from './types';
import { getData } from '../../utils/commonFunctions';

export const toggleNotifierStatusSuccess = () => ({
    type: NOTIFIER_STATUS.TOGGLE_SUCCESS,
});

export const toggleNotifierStatusFailure = (error: string) => ({
    type: NOTIFIER_STATUS.TOGGLE_FAILURE,
    payload: error,
});

export const fetchNotifierRequest = () => ({
    type: NOTIFIER_REDUX.FETCH_REQUEST,
});

export const fetchNotifierSuccess = (data: NotifierData[]) => ({
    type: NOTIFIER_REDUX.FETCH_SUCCESS,
    payload: data,
});

export const fetchNotifierFailure = (error: string) => ({
    type: NOTIFIER_REDUX.FETCH_FAILURE,
    payload: error,
});


export const fetchNotifierActionList = (projectId: string, accessToken: string, NotifierData?: any) => {
    return async (dispatch: Dispatch<NotifierAction>) => {
        dispatch(fetchNotifierRequest());
        try {
            // using this NotifierData for updated quicly Action List API commented code might be used
            // const response: NotifierData[] | any  = await getData({ project_id: projectId }, accessToken, NOTIFIER_ACTION_LIST);
            const response: any  = await getData({ project_id: projectId }, accessToken, NOTIFIER_ACTION_LIST);
            if (!response) {
                throw new Error('No response from API');
            }
            dispatch(fetchNotifierSuccess(response?.data));
        } catch (error: any) {
            dispatch(fetchNotifierFailure(error?.message || 'An error occurred'));
        }
    };
};


export const updateNotifierStatus = (
    actionId: string,
    toggleStatus: string,
    accessToken: string
) => {
    return async (dispatch: Dispatch<NotifierAction>) => {
        try {
            const payload = {
                action_id: actionId,
                toggle_status: toggleStatus,
            };
            await getData(payload, accessToken, NOTIFIER_ACTION_STATUS);
            const action = toggleNotifierStatusSuccess();
            dispatch(action);
            return action;
        } catch (error: any) {
            const action = toggleNotifierStatusFailure(error?.message || 'An error occurred');
            dispatch(action);
            return action;
        }
    };
};