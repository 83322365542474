import { Snackbar,Alert } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

//custom imports 
import { ShowErrorAlert, ShowSuccessAlert } from "../../store/alerts";
import { RootStateType } from "../../store";


export default function AlertPopUpComponent() {
  const dispatch = useDispatch();
  const alerts = useSelector((state: RootStateType) => state.alerts);

  const handleClose = () => {
    dispatch(ShowSuccessAlert(""));
  };

  const handleErrorClose = () => {
    dispatch(ShowErrorAlert(""));
  };

  const commonStyle = '!w-full !text-white !font-normal';

  return (
    <>
      <Snackbar
        className="red--whiteIcon"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(alerts.success.message)}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          className={`${commonStyle} !bg-[#5AAA77]`}
        >
          {alerts.success.message}
        </Alert>
      </Snackbar>
      <Snackbar
        className="red--whiteIcon"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(alerts.error.message)}
        autoHideDuration={3000}
        onClose={handleErrorClose}
      >
        <Alert
          onClose={handleErrorClose}
          severity="error"
          className={`${commonStyle} !bg-[#EB3751]`}
        >
          {alerts.error.message}
        </Alert>
      </Snackbar>
    </>
  );
}
