import { useSelector } from 'react-redux';
import { RootStateType } from '../../store/RootState';
import { Box, LinearProgress, CircularProgress, Backdrop } from '@mui/material';

export default function Loader() {
    return (
        <Backdrop className='!text-white !bg-black/65' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

export function ProgressBar() {
    const alerts = useSelector((state: RootStateType) => state.alerts);
    return (
        <>
            {alerts.loader.visible ?
                <Box component={'div'} className='!w-full !fixed z-[9999]'>
                    <LinearProgress className="!bg-[var(--brand--font--link)]" />
                </Box>
                : ''}
        </>
    )
}
