import './styles/App.scss';
import Routing from './Routing';
import { ProgressBar } from './components/common/Loader'
import AlertPopUpComponent from './components/common/AlertPopUp';
import CacheBuster from 'react-cache-buster';

import packageJson from '../package.json';


function App() {
  const version = packageJson.version;

  return (
    <>
      <CacheBuster
        currentVersion={version}
        isEnabled={true} //If false, the library is disabled.
        isVerboseMode={true} //If true, the library writes verbose logs to console.
      >
        <>
          <ProgressBar />
          <AlertPopUpComponent />
          <Routing />
        </>
      </CacheBuster>
    </>
  );
}

export default App;
