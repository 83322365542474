import { Reducer } from "redux";
import { NOTIFIER_REDUX, NOTIFIER_STATUS } from "../../constants";
import { NotifierState, NotifierAction } from "./types";

const initialState: NotifierState = {
    data: {
        notifier_actions_list: []
    },
    loading: false,
    error: null,
};

export const notifierReducer: Reducer<NotifierState, NotifierAction> = (state = initialState, action) => {
    switch (action.type) {
        case NOTIFIER_REDUX.FETCH_REQUEST:
            return { ...state, loading: true, error: null };
        case NOTIFIER_REDUX.FETCH_SUCCESS:
            return { ...state, loading: false, data: action.payload, error: null };
        case NOTIFIER_REDUX.FETCH_FAILURE:
            return { ...state, loading: false, error: action.payload };
            case NOTIFIER_STATUS.TOGGLE_SUCCESS:
                return state; 
            case NOTIFIER_STATUS.TOGGLE_FAILURE:
                return { ...state, error: action.payload };
            default:
                return state;
        }
};
