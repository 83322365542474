import { USER_REDUX_CONST, RESET } from "../../constants";
import {
    UpdateUserInfoType,
    ResetUserInfoType,
    userInfoParamsInterface,
} from "./types";

export const SaveUserInfo = (payload: userInfoParamsInterface): UpdateUserInfoType => ({
    type: USER_REDUX_CONST.UPDATE_USER_INFO,
    payload: payload
});

export const ResetUserInfo = (): ResetUserInfoType => ({
    type: RESET
});