import { ALERTS_REDUX } from "../../constants";
import {
    ShowSuccessAlertAction,
    ShowErrorAlertAction,
    ShowLoaderAction,
    HideLoaderAction,
} from "./types";

export const ShowSuccessAlert = (payload: string): ShowSuccessAlertAction => ({
    type: ALERTS_REDUX.SHOW_SUCCESS_ALERT,
    payload: payload
});

export const ShowErrorAlert = (payload: string): ShowErrorAlertAction => ({
    type: ALERTS_REDUX.SHOW_ERROR_ALERT,
    payload: payload
});

export const ShowProgressBar = (): ShowLoaderAction => ({
    type: ALERTS_REDUX.SHOW_LOADER
});

export const HideProgressBar = (): HideLoaderAction => ({
    type: ALERTS_REDUX.HIDE_LOADER
});

