import { FallbackProps } from "react-error-boundary";
import ErrorFallbackIcon from "./icons/ErrorFallbackIcon";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

const ErrorFallback: React.FC<FallbackProps> = ({error}) => {
  const navigate = useNavigate();
  const handleErrorReset = () => {
    navigate("/");
  };

  return (
    <div className="error-boundary-fallback-wrapper !text-center">
      <ErrorFallbackIcon />
      <h1>Something went wrong</h1>
      <p>{error?.message}</p>
      <Button className="save-btn" onClick={handleErrorReset}>Home</Button>
    </div>
  );
};

export default ErrorFallback;
