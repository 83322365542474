import { PROJECT_REDUX_CONST, RESET } from "../../constants";
import {
    ProjectListInfoType,
    ResetProjectsInfoType,
    projectListInfoParamsInterface,
    updateProjectKeyParamsInterface,
    UpdateProjectKeyType,
    setCurrentProjectParamsInterface,
    SetCurrentProjectType,
    ActivateInactivateProjectType,
    RemoveProjectType,
    UpdateProjectTitleType
} from "./types";



export const SaveProjectsList = (payload: projectListInfoParamsInterface): ProjectListInfoType => ({
    type: PROJECT_REDUX_CONST.SAVE_LIST,
    payload: payload
});


export const UpdateProjectKeyList = (payload: updateProjectKeyParamsInterface): UpdateProjectKeyType => ({
    type: PROJECT_REDUX_CONST.UPDATE_PROJECT_KEY,
    payload: payload
});


export const SetCurrentProject = (payload: setCurrentProjectParamsInterface): SetCurrentProjectType => ({
    type: PROJECT_REDUX_CONST.SET_CURRENT_PROJECT,
    payload: payload
});


export const ActivateInactivateProject = (): ActivateInactivateProjectType => ({
    type: PROJECT_REDUX_CONST.ACTIVATE_INACTIVATE_PROJECT,
});


export const ResetProjectInfo = (): ResetProjectsInfoType => ({
    type: RESET
});



export const RemoveProjectFromList = (payload: string): RemoveProjectType => ({
    type: PROJECT_REDUX_CONST.REMOVE_PROJECT,
    payload: payload
});


export const UpdateProjectTitle = (payload: string): UpdateProjectTitleType => ({
    type:  PROJECT_REDUX_CONST.UPDATE_PROJECT_TITLE,
    payload: payload
});
