import { regenratekeyApiResponse } from "../dataType/regenrateKey";
import { store } from "../store";
import {
  HideProgressBar,
  ShowProgressBar,
  ShowSuccessAlert,
} from "../store/alerts";
import { isApiCodeSucess, postRequest } from "./services";

export const getData = async (data: any, accessToken: string, url: string) => {
  // export const getData = async (params: { project_id: string }, token: string, url: string): Promise<NotifierResponse> => {
  const { dispatch } = store;
  dispatch(ShowProgressBar());
  try {
    let response = await postRequest(url, data, accessToken);
    if (response) {
      dispatch(HideProgressBar());
      if (isApiCodeSucess(response)) {
        try {
          let responseData = response.data as regenratekeyApiResponse;
          dispatch(ShowSuccessAlert(responseData.message));
          return responseData;
        } catch (error) {
          console.error(error);
        }
      }
    }
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(HideProgressBar());
  }

};

